import { FC, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";
import DataTable, { createTheme } from 'react-data-table-component';
import { formFields, formatAmt, menu } from "../Config";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import DataGrid from "../components/DataGrid";
import 'react-json-pretty/themes/monikai.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JSONPretty from "react-json-pretty";

interface Props {

}
declare global {
    interface Window {
        sendAlert: any;
        modalDisplay: any;
        reCreate: any;
    }
}


const Index: FC<Props> = () => {
    // const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
    const states = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState<any>([]);
    const [endpoint, setEndpoint] = useState("");
    const [data, setData] = useState<{ [key: string]: any | undefined }>({});
    const [forms, setForms] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);
    const [label, setLabel] = useState("");
    const [extra, setExtra] = useState([]);
    const [activeDraw, setActiveDraw] = useState<{ [key: string]: any }>({});
    const [details, setDetails] = useState<{ [key: string]: any }>({});
    const [filterPayload, setFilterPayload] = useState<{ [key: string]: any | undefined }>({});

    const ussdLabels = ["", "", "Input", "Termination"]
    const playStatus = ["Pending", "Paid", "Rewarded"]
    const gameStatus = ["Inactive", "Active"]
    const [processing, setProcessing] = useState(0);
    const [processingSMS, setProcessingSMS] = useState(0);
    const [log, setLog] = useState<any>([]);
    const [logDetails, setLogDetails] = useState<any>({});
    const [url, setUrl] = useState<any>("");
    const [columns, setColumns] = useState<any>([]);

    const [count, setCount] = useState<any>(0);
    const [page, setPage] = useState<any>(1);
    const [perPage, setPerPage] = useState<any>(10);
    const [ticketData, setTicketData] = useState<any>({});
    const [game, setGame] = useState<any>({});

    const getdataSet = async () => {
        setLoading(true);
        // window.reCreate("datagrid");
        const path = (url.includes("?")) ? `` : `?`
        noDataHttp(`${url}${path}page=${page}&perPage=${perPage}`).then((results: any) => {
            if (results.code === 200) {
                setDataSet(results?.message?.data || results?.message);
                setCount(results?.count || 0);
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteRecord = (id: string | number) => {
        setLoading(true);
        noDataHttp(`${endpoint}/${id}`, "DELETE").then((results: any) => {
            if (results.code === 200) {
                getdataSet();
                toast.success(results?.message)
            } else {
                toast.error(results?.message)
            }
        }).catch(err => {
            toast.error(err?.message)
        }).finally(() => {
            setLoading(false);
        });
    }



    const editRecord = (id: string | number) => {
        navigate(`/edit/${id}`, { state: data });
    }

    const valueExpression = (field: any, value: string) => {
        if (field.value === "requestType")
            return ussdLabels[Number(value || 0)];
        else if (field.value === "status") {
            let status = ["Pending", "Paid", "Rewarded"];
            if (data.label === "Games")
                status = ["Inactive", "Active"];

            return status[Number(value || 0)];
        } else if (['date', 'timestamp'].includes(field.datatype)) {
            return <div style={{ whiteSpace: 'break-spaces' }}>{value === null ? '----:--:--' : value?.replace('T', '\n')?.replace('.000Z', '')}</div>;
        } else if (["totalPayout", "collections", "drawPayout", "drawCollections",
            "totalPayout", "payoutAmount"].includes(field.value))
            return value ? formatAmt(Number(value)) : '--.--';
        else
            return <div style={{ whiteSpace: 'break-spaces' }}>{value}</div>
    }

    const filters = (endpoint: string) => {
        const btn = <>
            <div className="mb-3 col-auto">
                <button type="button" onClick={() => filterData()} className="btn btn-primary mt-4">Submit</button>
            </div>
            <div className="mb-3 col-auto">
                <button type="button" onClick={() => window.location.reload()} className="btn btn-secondary mt-4">Clear</button>
            </div>
        </>;
        const commonFilter: any = [];

        if (endpoint.includes("draws")) {
            return <form>
                <div className="row mb-3">
                    <div className="mb-3 col-4">
                        <label className="form-label" >Draw ID</label>
                        <input type="text" className="form-control" value={filterPayload['id']} onChange={(e) => filterChange("id", e.target.value)} />
                    </div>
                    <div className="mb-3 col">
                        <label className="form-label" >Start Date</label>
                        <input type="date" className="form-control" onChange={(e) => filterChange("startDate", e.target.value)} />
                    </div>
                    <div className="mb-3 col">
                        <label className="form-label" >End Date</label>
                        <input type="date" className="form-control" onChange={(e) => filterChange("endDate", e.target.value)} />
                    </div>
                    {btn}
                </div>
            </form>;
        } else if (["tickets1"].includes(endpoint)) {
            return <form>
                <div className="row mb-3">
                    {commonFilter}
                    <div className="mb-3 col-4">
                        <label className="form-label" >Ticket ID</label>
                        <input type="text" className="form-control" value={filterPayload['id']} onChange={(e) => filterChange("id", e.target.value)} />
                    </div>
                    <div className="mb-3 col-4">
                        <label className="form-label" >Mobile</label>
                        <input type="text" className="form-control" value={filterPayload['mobileNumber']} onChange={(e) => filterChange("mobileNumber", e.target.value)} />
                    </div>
                    <div className="mb-3 col-4">
                        <label className="form-label" >Status</label>
                        <select
                            className="form-control"
                            onChange={(e) => filterChange("status", e.target.value)}>
                            <option value=" ">All Status</option>
                            <option value="0">Pending</option>
                            <option value="1" selected>Paid</option>
                            <option value="2">Rewarded</option>
                        </select>
                    </div>
                    <div className="mb-3 col-4">
                        <label className="form-label" >Start Date</label>
                        <input type="date" className="form-control" onChange={(e) => filterChange("startDate", e.target.value)} />
                    </div>
                    <div className="mb-3 col-4">
                        <label className="form-label" >End Date</label>
                        <input type="date" className="form-control" onChange={(e) => filterChange("endDate", e.target.value)} />
                    </div>
                    {btn}
                </div>
            </form>;
        } else {
            return <></>;
        }
    }

    const filterData = () => {
        if (Object.keys(filterPayload).length > 0) {
            let extraParams: any = [];

            Object.keys(filterPayload).filter(f => filterPayload[f]).map(filter => {
                extraParams.push(`${filter}=${filterPayload[filter]}`);
            });
            setUrl(`${endpoint}${endpoint.includes("?") ? "&" : "?"}${extraParams.join("&")}&`)
            // getdataSet();
        } else {
            setUrl(`${endpoint}${endpoint.includes("?") ? "&" : "?"}${extra.join("&")}`)
            // getdataSet();
        }
    }

    const filterChange = (objkey: string, objvalue: string) => {
        const payL: any = {};
        payL[objkey] = objvalue;

        setFilterPayload({ ...filterPayload, ...payL });
    }

    const viewDetails = (data: any) => {
        setLoading(true);
        window.reCreate("detailsTable");
        window.reCreate("winnersTable");
        setActiveDraw(data);
        noDataHttp(`breakdown/${data.id}`).then((results: any) => {
            setDetails(results?.message);
            window.modalDisplay("detailsModal", 1);
            setTimeout(() => {
                window.sendAlert("detailsTable");
                window.sendAlert("winnersTable");
            }, 200);
        }).finally(() => {
            setLoading(false);
        });
    }
    const viewTicket = (data: any) => {
        setLoading(true);
        noDataHttp(`tickets1/${data.id}`).then((results: any) => {
            setTicketData({ ...results?.message });
            window.modalDisplay("ticketModal", 1);
        }).finally(() => {
            setLoading(false);
        });
    }

    const viewLogs = (data: any) => {
        setLoading(true);
        setActiveDraw(data);
        window.reCreate("logsTable");
        // console.log(data);
        dataHttp(`logscontent`, { 'path': data.file }, true, 'POST').then((results: any) => {
            setLog(results?.sort((a: any, b: any) => a.id > b.id ? -1 : 1));
            window.modalDisplay("logsModal", 1);
            setTimeout(() => {
                window.sendAlert("logsTable");
            }, 200);
        }).finally(() => {
            setLoading(false);
        });
    }

    const processingPayment = (data: { [key: string]: any }) => {
        setProcessing(data.id);
        dataHttp(`payuser`, {
            drawid: details?.draw?.id,
            ticket: data?.id,
            gameid: details?.draw?.gameID
        }, true, "POST").then((results: any) => {
            setError(results.message);

            if (results.message.includes("already been paid") ||
                results.message.includes("done successfully")) {
                viewDetails(activeDraw);
            }
        }).finally(() => {
            setProcessing(0);
        });
    }

    const winSMS = (data: { [key: string]: any }) => {
        setProcessingSMS(data.id);
        dataHttp(`losersms`, {
            drawid: details?.draw?.id,
            ticket: data?.id,
            gameid: details?.draw?.gameID
        }, true, "POST").then((results: any) => {
            setError(results.message);
        }).finally(() => {
            setProcessingSMS(0);
        });
    }

    const sendLosingmsg = () => {
        setLoading(data.id);
        dataHttp(`loserssms`, {
            drawid: details?.draw?.id,
            gameid: details?.draw?.gameID
        }, true, "POST").then((results: any) => {
            setError(results.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPage(page);
        setPerPage(newPerPage);
    };
    useEffect(() => {
        getdataSet();
    }, [page, perPage]);

    const loadPageData = () => {
        const activeGame = localStorage.getItem('activegame');
        const game_ = states?.games.find((f: any) => Number(f.id) === Number(activeGame));
        setGame(game_);
        //actions
        const stateData: any = location?.state;
        setActions(stateData?.actions?.split(",") || []);
        //the columns
        const form: any = formFields(stateData?.endpoint || []);

        setForms(form || []);
        setLabel(stateData.label);
        setEndpoint(stateData?.endpoint);
        const payLoad_: { [key: string]: any } = {};
        setData(stateData);
        setUrl(`${stateData.endpoint}${stateData.endpoint.includes("?") ? "&" : "?"}${extra.join("&")}`)
    }

    useEffect(() => {
        setForms([]);
        setUrl("");
        setFilterPayload({});
        loadPageData();
    }, [location]);

    useEffect(() => {
        getdataSet();
    }, [data, url]);

    useEffect(() => {
        const cols = forms.map((f: any) => {
            const col: any = {
                name: <div>{f.label?.toUpperCase()}</div>,
                cell: (row: any) => valueExpression(f, row[f.value]),
                sortable: true
            };
            if (['Draw ID', 'Winning number', 'Total Plays', 'Payout Count', 'ID', 'Request Type', 'Game', 'Menu Order', 'SMS Type', 'Game ID'].includes(f.label))
                col['width'] = '70px';
            else if (['Timestamp', 'Draw Payout', 'Minimum Stake'
                , 'Maximum Stake'
                , 'Minimum Number'
                , 'Maximum Number'
                , 'Draw Frequency'
                , 'Cron Schedule'
                , 'Payout Threshold'
                , 'Multiplier'
                , 'Win Selection'
                , 'Status'
                , 'Callback Url'
                , 'Payment Gateway Url'
                , 'Minimum Stake',
                , 'Maximum Stake',
                , 'Minimum Number',
                , 'Maximum Number',
                , 'Draw Frequency',
                , 'Cron Schedule',
                , 'Payout Threshold',
                , 'Multiplier',
                , 'Win Selection',
                , 'Status',
                , 'Callback Url',
                , 'Payment Gateway Url'].includes(f.label))
                col['width'] = '110px';

            return (col);
        });

        if ((actions || []).length) {
            (cols).push({
                name: '',
                cell: (row: any) => {
                    return <>{[...actions?.map((a: any) => {
                        let btn = '';
                        if (a === 'details')
                            return (<a onClick={() => { setError(""); viewDetails(row) }} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bx-pen"></i>
                            </a>)
                        else if (a === 'view')
                            return < a onClick={() => editRecord(row?.id)} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bxs-info-circle"></i>
                            </a >
                        else if (a === 'edit')
                            return <a onClick={() => editRecord(row?.id)} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bx-pencil"></i>
                            </a>
                        else if (a === 'delete')
                            return <a onClick={() => deleteRecord(row?.id)} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bx-trash"></i>
                            </a>
                        else if (a === 'details')
                            return <a onClick={() => { setError(""); viewDetails(row) }} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bx-pen"></i>
                            </a>
                        else if (a === 'viewticket')
                            return <a onClick={() => { setError(""); viewTicket(row) }} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bxs-info-circle"></i>
                            </a>
                        else if (a === 'viewlog')
                            return <a onClick={() => {
                                setError("");
                                setLogDetails(row);
                                setTimeout(() => {
                                    viewLogs(row);
                                }, 1000);
                            }} style={{ marginRight: 10 }}>
                                <i className="icon-icon tf-icons bx bx-info-circle"></i>
                            </a>
                    })]}</>
                },
                sortable: true,
                width: `${(actions || []).length * 35}px`
            });
        }
        setColumns([{
            name: 'ID',
            cell: (row: any) => row?.id,
            sortable: true,
            width: '70px'
        }, ...cols]);

    }, [forms]);

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> {data?.label}</h4>

                <div className="row">
                    <div className="col-xxl">
                        <div className="card mb-4">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">{label} <small style={{ fontSize: 12 }}>({game?.name})</small></h5>
                                <small className="text-muted float-end" style={{ cursor: 'pointer' }}>Filters</small>
                            </div>
                            {loading && <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>}
                            {endpoint === '/odds' ?
                                <div className="card-body">
                                    <table id="datagrid" className="stripe" style={{ width: "100%" }}>
                                        {Object.keys(dataSet).filter(f => ['cumulativeData', 'payoutSelected', 'winner', 'eligibleNumbers'].includes(f))?.map(v => <tr>
                                            <td width="5%">{v}</td>
                                            <td width="10%">
                                                {
                                                    Array.isArray(dataSet[v]) ?
                                                        <table id="datagrid" className="stripe" style={{ width: "100%" }}>
                                                            {dataSet?.[v].map((m: any) => Object.keys(m))?.[0].map((f: any) => <td>{f}</td>)}
                                                            {dataSet?.[v].map((m: any) => <tr>
                                                                {Object.keys(m).map(k => <td>{m[k]}</td>)}
                                                            </tr>)}
                                                        </table>
                                                        :
                                                        JSON.stringify(dataSet[v])
                                                }
                                            </td>
                                        </tr>)}
                                    </table>
                                    {/* <DataGrid
                                                        columns={[
                                                            {
                                                                name: 'COUNT',
                                                                cell: (row: any) => row.count,
                                                                sortable: true,
                                                            }, {
                                                                name: 'STAKE',
                                                                cell: (row: any) => 'GHS ' + row?.stake,
                                                                sortable: true,
                                                            }, {
                                                                name: 'PAYOUT',
                                                                cell: (row: any) => 'GHS ' + row.payout,
                                                                sortable: true,
                                                            }, {
                                                                name: 'NUMBER PLAYED',
                                                                cell: (row: any) => row.playedNumbers,
                                                                sortable: true,
                                                            }
                                                        ]}
                                                        data={dataSet?.eligibleNumbers || []}
                                                        progressPending={loading}
                                                    /> */}
                                </div>
                                :
                                <div className="card-body">
                                    <div className="filters">
                                        {filters(endpoint)}
                                    </div>
                                    <DataGrid
                                        columns={columns}
                                        data={dataSet}
                                        progressPending={false}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={count}
                                        paginationDefaultPage={page}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                    />

                                </div>}
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-xl" id="detailsModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Draw #{activeDraw?.id} <br /><small style={{ fontWeight: 'normal', fontSize: 12 }}> Draw Timestamp : {activeDraw?.drawTimestamp?.replace("T", " ").replace(".000Z", "")}</small></h5>
                            <a style={{ cursor: 'pointer' }} onClick={() => {
                                window.modalDisplay("detailsModal", 0)
                            }}>&times;</a>
                        </div>
                        <div className="modal-body">
                            <div className="card px-3 py-3">
                                <h5 className="modal-title" id="exampleModalLongTitle mt-10">Play Data</h5><hr />
                                <DataGrid
                                    columns={[
                                        {
                                            name: <div>Stake</div>,
                                            cell: (row: any) => row?.stake,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Multiplier</div>,
                                            cell: (row: any) => details?.draw?.multiplier,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Payout</div>,
                                            cell: (row: any) => row?.payout,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Played Number</div>,
                                            cell: (row: any) => row?.playedNumbers,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Play Count</div>,
                                            cell: (row: any) => row?.count,
                                            sortable: true
                                        }
                                    ]}
                                    data={details?.message}
                                    progressPending={false}
                                    pagination
                                    paginationServer
                                />
                            </div>

                            <div className="card px-3 py-3 mt-5">
                                {error !== "" && <div className={`alert alert-primary mb-3`} role="alert">{error}</div>}
                                <h5 className="modal-title" id="exampleModalLongTitle mt-10">Winner List</h5><hr />
                                <DataGrid
                                    columns={[
                                        {
                                            name: <div>Ticket ID</div>,
                                            cell: (row: any) => row?.id,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Mobile Number</div>,
                                            cell: (row: any) => row?.mobileNumber,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Stake</div>,
                                            cell: (row: any) => row?.stake,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Payout</div>,
                                            cell: (row: any) => row?.payout,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Played Number</div>,
                                            cell: (row: any) => row?.playedNumbers,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Timestamp</div>,
                                            cell: (row: any) => <div style={{ whiteSpace: 'break-spaces' }}>{row.playTimestamp.replace("T", " ").replace(".000Z", "")}</div>,
                                            sortable: true
                                        },
                                        {
                                            name: <div>Status</div>,
                                            cell: (row: any) => data.label === 'games' ? gameStatus[row.status] : playStatus[row.status],
                                            sortable: true
                                        },
                                        {
                                            name: '',
                                            cell: (row: any) => {
                                                if (row.status === 1) {
                                                    return processing === row.id
                                                        ? <div className="text-center">
                                                            <div className="spinner-border text-primary" role="status">
                                                            </div>
                                                        </div>
                                                        : <a onClick={() => processingPayment(row)}>
                                                            <i className="icon-icon tf-icons bx bxs-credit-card"></i>
                                                        </a>
                                                }
                                            },
                                            sortable: true,
                                            width: '40px'
                                        },
                                        {
                                            name: '',
                                            cell: (row: any) => {
                                                if (row.status === 1) {
                                                    return processingSMS === row.id
                                                        ? <div className="text-center">
                                                            <div className="spinner-border text-secondary" role="status">
                                                            </div>
                                                        </div>
                                                        : <a onClick={() => winSMS(row)}>
                                                            <i className='icon-icon bx bxs-bell-ring' ></i>
                                                        </a>
                                                }
                                            },
                                            sortable: true,
                                            width: '40px'
                                        }
                                    ]}
                                    data={details?.winners}
                                    progressPending={false}
                                    pagination
                                    paginationServer
                                />
                            </div>
                        </div>
                        {loading
                            ? <div className="text-center">
                                <div className="spinner-border text-secondary" role="status">
                                </div>
                            </div> :
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                    window.modalDisplay("detailsModal", 0)
                                }}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => sendLosingmsg()}>Send Losing Messages</button>
                            </div>}
                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-xl" id="logsModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Draw #{activeDraw?.id} <br /><small style={{ fontWeight: 'normal', fontSize: 12 }}> Draw Timestamp : {activeDraw?.drawTimestamp?.replace("T", " ").replace(".000Z", "")}</small></h5>
                            <a style={{ cursor: 'pointer' }} onClick={() => {
                                window.modalDisplay("logsModal", 0)
                            }}>&times;</a>
                        </div>
                        <div className="modal-body">
                            <div className="card px-3 py-3">
                                <h5 className="modal-title" id="exampleModalLongTitle mt-10">Play Data</h5><hr />
                                <table id="logsTable" className="stripe" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Timestamp</th>
                                            <th>Trans. Type</th>
                                            <th>Log Activity</th>
                                            <th>Log Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {log?.map((row: any, i: number) => {
                                            return (<tr key={`logs${i}`}>
                                                <td>{row?.date}</td>
                                                <td>{row?.reqType}</td>
                                                <td>{row?.log}</td>
                                                <td style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                    {/* <pre>{JSON.stringify(row?.response, null, 2)}</pre> */}
                                                    <JSONPretty data={row?.response} ></JSONPretty>
                                                </td>

                                            </tr>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                window.modalDisplay("logsModal", 0)
                            }}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ticket details */}
            {!!ticketData?.results?.id &&
                <div className="modal fade bd-example-modal-xl" id="ticketModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Play Ticket #{ticketData?.results?.id} <br /><small style={{ fontWeight: 'normal', fontSize: 12 }}> Player : {ticketData?.results?.mobileNumber}</small></h5>
                                <a style={{ cursor: 'pointer' }} onClick={() => {
                                    window.modalDisplay("ticketModal", 0)
                                }}>&times;</a>
                            </div>
                            <div className="modal-body">
                                <div className="card px-3 py-3">
                                    <h5 className="modal-title" id="exampleModalLongTitle mt-10">Play Details</h5><hr />

                                    {Object.keys(ticketData?.results).map(m => <div className="row border-bottom p-2">
                                        <div className="col-3">{m}</div>
                                        <div className="col-auto">{ticketData?.results[m]}</div>
                                    </div>)}

                                    {ticketData?.purchase &&
                                        <div className="row border-bottom p-2">
                                            <div className="col-3">Purchase Verified</div>
                                            <div className="col-auto">{ticketData?.purchase?.message?.trans_status?.toUpperCase()?.includes("SUCCESS") ||
                                                ticketData?.purchase?.message?.message?.toUpperCase()?.includes("SUCCESS") ?
                                                <button type="button" className="btn btn-success btn-sm">Success</button> :
                                                <button type="button" className="btn btn-danger btn-sm">Failed</button>}</div>
                                        </div>
                                    }
                                    {ticketData?.payout &&
                                        <div className="row border-bottom p-2">
                                            <div className="col-3">Payout Verified</div>
                                            <div className="col-auto">{["000/0", '000/01', '000/200'].includes(ticketData?.payout?.message?.trans_status) ?
                                                <button type="button" className="btn btn-success btn-sm">Success</button> :
                                                <button type="button" className="btn btn-danger btn-sm">Failed</button>}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                    window.modalDisplay("ticketModal", 0)
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

export default Index;