import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";
import moment from "moment";
import { formatAmt } from "../Config";
import { url } from "inspector";
import DataGrid from "../components/DataGrid";
import { useDispatch } from "react-redux";
import { setGames, setWallets } from "../Reducers/userSlice";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../store";

declare global {
    interface Window {
        pieGraph: any;
        lineGraph: any;
        incomeGraph: any;
        StatisticsChart: any;
    }
}

const Index: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [noteType, setNoteType] = useState('alert-danger');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const tempReport = [
        {
            "label": "Collection",
            "value": "--.--",
            "display": "GHS --.--",
            "PayoutPercent": "0%"
        },
        {
            "label": "Payout",
            "value": "--.--",
            "display": "GHS --.--",
            "PayoutPercent": "--.--"
        },
        {
            "label": "Profit",
            "value": '--.--',
            "display": "GHS --.--",
            "PayoutPercent": "--.--"
        }
    ]
    const [reports, setReports] = useState<{ [key: string]: any }>({
        "appsnmobile": {
            "sms_bal": '--',
            "payout_bal": '--.--',
            "billpay_bal": '--',
            "available_collect_bal": '--.--',
            "airtime_bal": '--',
            "actual_collect_bal": '--.--'
        },
        "dayCollection": [
            {
                "collection": '--.--',
                "payout": null,
                "count": '--.--',
                "status": '--.--'
            },
            {
                "collection": '--.--',
                "payout": '--.--',
                "count": '--.--',
                "status": '--.--'
            }
        ],
        "monthCollection": tempReport,
        "sMonth": tempReport,
        "dMonth": tempReport
    });
    const states = useSelector((state: RootState) => state.user);
    const [game, setGame] = useState("1");
    const [tickets, setTickets] = useState<any>([]);
    const [resolving, setResolving] = useState(false);
    const [processing, setProcessing] = useState('0');
    const [page, setPage] = useState<any>(1);
    const [perPage, setPerPage] = useState<any>(10);
    const [manualDraw, setManualDraw] = useState<any>({});
    const user = JSON.parse(localStorage.getItem("ncarta_user") || '{}');
    const [activeTab, setActiveTab] = useState(1);
    const [queryResult, setQueryResult] = useState<any>({});
    const defaultTestInput = {
        "shortcode": {
            placeholder: 'Enter shortcode eg *899*98#',
            value: "",
            dataSet: ['*899*98', '*741*2#']
        },
        "mobile": {
            placeholder: 'Enter mobile number eg 233221000000',
            value: ""
        },
        "network": {
            placeholder: 'Enter network',
            value: "",
            dataSet: ['MTN', 'Vodafone', 'AirtelTigo']
        }
    };
    const [testResult, setTestResult] = useState<{ [key: string]: any }>({
        input: defaultTestInput,
        loading: false,
        step: 1,
        sessionId: '',
        response: ''
    });

    const homeAccess = states?.accesses?.accesses?.find((f: any) => f.label === 'Home') || {};

    const columns = [
        { label: 'Ticket ID', value: 'ticketID' },
        { label: 'Game', value: 'category' },
        { label: 'Mobile Number', value: 'mobileNumber' },
        { label: 'Stake', value: 'stake' },
        { label: 'Payout', value: 'payoutAmount' },
        { label: 'Played Number', value: 'playedNumbers' },
        { label: 'Draw Win Nos.', value: 'winningNumbers' },
        { label: 'Draw ID', value: 'drawID' },
        { label: 'Draw Time', value: 'drawTimestamp' },
        { label: 'Played Timestamp', value: 'playTimestamp' },
        { label: '', value: 'reslovebtn' },
        { label: '', value: 'deletebtn' }
    ].map((f: any) => {
        const col: any = {
            name: <div>{f.label?.toUpperCase()}</div>,
            cell: (row: any) => {
                if (f.value === "reslovebtn")
                    if (processing === row.ticketID)
                        return <div className="text-center">
                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                            </div>
                        </div>
                    else
                        return <a onClick={() => resolveIssue(row)}><i className="icon-icon tf-icons bx bxs-credit-card"></i></a>
                else if (f.value === "deletebtn")
                    if (processing === row.ticketID)
                        return <div className="text-center">
                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                            </div>
                        </div>
                    else
                        return <a onClick={() => deleteTicketIssue(row)}><i className="icon-icon tf-icons bx bxs-trash"></i></a>
                else if (['payoutAmount', 'stake'].includes(f.value))
                    return `GHS ${row[f.value]}`;
                else if (["drawTimestamp", 'playTimestamp'].includes(f.value))
                    return row[f.value].replace("T", " ").replace(".000Z", "")
                return row[f.value];
            },
            sortable: true
        };
        if (['stake', 'payoutAmount', 'winningNumbers', 'drawID'].includes(f.value))
            col['width'] = '80px';
        else if (f.value.includes("btn"))
            col['width'] = '30px';
        else if (['timestamp'].includes(f.label?.toLowerCase()))
            col['width'] = '160px';

        return (col);
    });

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    useEffect(() => {
        setGame(localStorage.getItem("activegame") || "1");
        getDataSet('dashboard');
        getGame();
        // getTickets('issuetickets');
    }, []);

    const getTickets = async (url: string) => {
        setLoading(true);
        window.reCreate("winnersTable");
        noDataHttp(`${url}?page=${page}&perPage=${perPage}`, "GET").then((results: any) => {
            if (results?.code === 200)
                setTickets(results?.message);
            setTimeout(() => {
                window.sendAlert("winnersTable");
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }

    const resolveIssue = async (issue: any) => {
        setResolving(true);
        setError('');
        setNoteType("alert-danger");
        setProcessing(issue?.ticketID);
        dataHttp(`payuser`, {
            drawid: issue?.drawID,
            ticket: issue?.ticketID,
            gameid: issue?.gameID
        }, true, "POST").then((results: any) => {
            setError(results.message);
            setNoteType("alert-success");
            if (results.message.includes("already been paid") ||
                results.message.includes("done successfully")) {
                getTickets('issuetickets');
            }
        }).finally(() => {
            setResolving(false);
            setProcessing('0');
        });
    }

    const deleteTicketIssue = async (issue: any) => {
        setResolving(true);
        setError('');
        setNoteType("alert-danger");
        setProcessing(issue?.ticketID);
        noDataHttp(`resolveissuetickets/${issue?.ticketID}`, "DELETE").then((results: any) => {
            setError(results.message);
            setNoteType("alert-success");

        }).finally(() => {
            setResolving(false);
            setProcessing('0');
            getTickets('issuetickets');
        });
    }

    const getGame = async () => {
        setLoading(true);
        noDataHttp(`games`, "GET").then((results: any) => {
            dispatch(setGames(results?.message));
        }).catch(err => {
            console.log(err)
        });

    }

    const getWallets = async () => {
        noDataHttp(`wallets`, "GET").then((results: any) => {
            dispatch(setWallets(results?.message));
        }).catch(err => {
            console.log(err)
        });
    }

    const getDataSet = async (url: string) => {
        noDataHttp(`${url}?game=${game}`, "GET").then((response: any) => {
            const results = response?.message;
            setReports(results);
            const lbs = results.dayonday.map((d: any) => moment(d.date).format("Do"));
            window.lineGraph([
                {
                    name: "Collections",
                    data: results.dayonday.map((d: any) => d.collection.toFixed(2))
                },
                {
                    name: "Payout",
                    data: results.dayonday.map((d: any) => Number(d?.payout || 0).toFixed(2))
                }
            ], 'monthlytrend', lbs);

            const lbs1 = ["", ...results.monthonmonth.map((d: any) => months[d.month])];
            window.incomeGraph(results.monthonmonth.map((d: any) => (Number(d.collection) - Number(d.payout)).toFixed(2)), 'incomeGraph1', lbs1, "#696cff");

            let lbs2: any = ["Collection", "Payout"];
            const data_ = results?.dayCollection.filter((f: any) => f.status === 1)[0];
            const dataS = Object.keys(data_ || {})?.map((d: string) => {
                if (["collection", "payout"].includes(d)) {
                    return data_[d];
                }
            }).filter((f: any) => f !== undefined);
            const total = results?.dayCollection?.map((m: any) => m.count).reduce((prev: number, curr: number) => prev + curr, 0) || 0;
            const percentageSplit = ((results?.dayCollection[0]?.count || 0 / total) * 100).toFixed(2);
            window.StatisticsChart(results?.dayCollection.map((m: any) => {
                return m.count
            }), 'monthbreakStats', ["Failed Plays", "Successful Plays", "Wins"], ["#ff3e1d", "#03c3ec", "#71dd37"], percentageSplit, total);

        }).finally(() => {
            setLoading(false);
        });


    }

    const getIcon = (label: string) => {
        if (["Payout", "payout_bal"].includes(label))
            return "paypal.png";
        else if (["Collection", "available_collect_bal"].includes(label))
            return "cc-primary.png";
        else if (label === "Count")
            return "chart.png";
        else
            return "chart-success.png";
    }

    useEffect(() => {
        setTimeout(() => {
            if (reports?.monthonmonth !== undefined) {
                const lbs1 = ["", ...reports.monthonmonth.map((d: any) => months[d.month])];
                if (activeTab === 1)
                    window.incomeGraph(reports.monthonmonth.map((d: any) => (Number(d.collection) - Number(d.payout)).toFixed(2)), 'incomeGraph1', lbs1, "#696cff");
                else if (activeTab === 2)
                    window.incomeGraph(reports.monthonmonth.map((d: any) => d.payout.toFixed(2)), 'incomeGraph2', lbs1, "#ff3e1d");
                else if (activeTab === 3)
                    window.incomeGraph(reports.monthonmonth.map((d: any) => d.collection.toFixed(2)), 'incomeGraph3', lbs1, "#71dd37");
            }
        })
    }, [activeTab]);

    const formatlabel = (label: string) => {
        label = label.split("_").join(" ");
        label = label.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())

        return label;
    }

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPage(page);
        setPerPage(newPerPage);
    };

    const checkManualDraw = async () => {
        if (manualDraw?.draw && manualDraw?.gameID) {
            setManualDraw((prev: any) => ({ ...prev, loading: true }));
            const check = await dataHttp(`manualdraw`, {
                draw: manualDraw?.draw,
                gameID: manualDraw?.gameID
            }, true, "POST");
            setManualDraw((prev: any) => ({ ...prev, data: check }));
            setManualDraw((prev: any) => ({ ...prev, loading: false }));
        }
    }

    const rawQuery = () => {
        setQueryResult((prev: any) => ({ ...prev, loading: true }));
        dataHttp(
            '/rawquery',
            queryResult?.payload || {},
            true,
            'POST').then((result: any) => {
                setQueryResult((prev: any) => ({ ...prev, result: result || {} }));
            }).catch(err => {
                // setError(err);
            }).finally(() => {
                setQueryResult((prev: any) => ({ ...prev, loading: false }));
            });

    }

    useEffect(() => {
        getTickets('issuetickets');
    }, [page, perPage]);

    const resetTest = () => {
        setTestResult({
            input: defaultTestInput,
            loading: false,
            step: 1,
            sessionId: '',
            response: ''
        })
    }

    const testGameBtn = () => {
        let sessionId = "";
        let step = Number(testResult?.step) + 1;
        const payload = testResult?.payload ? testResult?.payload : {
            sessionId: testResult?.sessionId,
            requestType: 2,
            userData: '',
            network: testResult?.input?.network?.value,
            msisdn: testResult?.input?.mobile?.value
        }
        if (testResult?.step === 1) {
            sessionId = Array(3).fill(1).map(m => Math.random() * (99999 - 10000) + 10000).join("");
            payload.sessionId = sessionId;
            payload.userData = testResult?.input?.shortcode?.value;
            payload.requestType = 1;
        } else {
            payload.userData = testResult?.input?.userData?.value;
            payload.requestType = 2;
        }

        axios.post('https://instacash-api.ncartabox.com/api/v1.0/user/ussd', payload).then(results => {
            let input = step === 5 ? {} : {
                "userData": {
                    placeholder: 'Enter selection here',
                    value: ""
                }
            }

            payload.requestType = results?.data?.requestType;

            setTestResult((prev: any) => ({ ...prev, payload, input, response: results?.data?.respMsg || '', step: step }));
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                {loading === true && <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
                <div className="row">
                    {homeAccess?.actions?.split(",").includes("stats") &&
                        <div className="col-lg-12 col-md-12 order-1" style={{ minHeight: 200 }}>
                            <div className="row">
                                {reports?.sMonth &&
                                    reports.sMonth.map((report: any, index: number) => {
                                        return (<div className="col mb-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="card-title d-flex align-items-start justify-content-between">
                                                        <div className="avatar flex-shrink-0">
                                                            <img
                                                                src={`../assets/img/icons/unicons/${getIcon(report.label)}`}
                                                                alt="chart success"
                                                                className="rounded"
                                                            />
                                                        </div>
                                                    </div>
                                                    <span className="fw-semibold d-block mb-1">{report.label}</span>
                                                    <strong className="card-title mb-2">{report.display} <small style={{ fontSize: 10 }}>(Month)</small></strong>
                                                    {report.PayoutPercent ? <small className="text-info fw-semibold"><i className="bx bx-up-arrow-alt"></i> {report.PayoutPercent}</small>
                                                        : <small className="text-info fw-semibold">.</small>}

                                                    {reports?.dMonth[index] && <div className="mt-2"><strong className=" mb-2">{reports?.dMonth[index].display} <small style={{ fontSize: 10 }}>(Today)</small></strong>
                                                        {report.PayoutPercent ? <small className="text-danger fw-semibold"><i className="bx bx-up-arrow-alt"></i> {reports?.dMonth[index].PayoutPercent}</small>
                                                            : <small className="text-danger fw-semibold">.</small>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>);
                                    })
                                }

                            </div>
                        </div>
                    }

                    {homeAccess?.actions?.split(",").includes("trend") &&
                        <div className="col-12 col-lg-8 order-2 order-md-3 order-lg-2 mb-4">
                            <div className="card">
                                <div className="row row-bordered g-0">
                                    <div className="col-md-12" style={{ minHeight: 400 }}>
                                        <div id="monthlytrend" className="py-4 px-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {homeAccess?.actions?.split(",").includes("trend") &&
                        <div className="col-12 col-md-8 col-lg-4 order-3 order-md-2">
                            <div className="row">
                                {reports?.appsnmobile &&
                                    Object.keys(reports?.appsnmobile)
                                        .filter((f: any) => ["payout_bal", "available_collect_bal"].includes(f))
                                        .map((m: any) => {
                                            return (<div className="col-6 mb-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title d-flex align-items-start justify-content-between">
                                                            <div className="avatar flex-shrink-0">
                                                                <img src={`../assets/img/icons/unicons/${getIcon(m)}`} alt="Credit Card" className="rounded" />
                                                            </div>

                                                        </div>
                                                        <small className="d-block mb-1">AppsNMobile</small>
                                                        <small className="d-block mb-1"><b> {formatlabel(m)}</b></small>
                                                        <strong className={`card-title text-nowrap mb-2 ${m === 'payout_bal' ? 'text-danger' : 'text-primary'}`}>{formatAmt(reports?.appsnmobile[m])}</strong>
                                                        {/* <small className="text-danger fw-semibold"><i className="bx bx-down-arrow-alt"></i> -14.82%</small> */}
                                                    </div>
                                                </div>
                                            </div>);
                                        })}
                                {reports?.appsnmobile?.extra_detail &&
                                    <div className="col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between flex-sm-row flex-column">
                                                    <div className="d-flex flex-sm-column flex-row align-items-start justify-content-between">
                                                        <div className="card-title">
                                                            <h5 className="text-nowrap mb-2">Collections BreakDown</h5>
                                                            <small>(Mobile Networks)</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {reports?.appsnmobile?.extra_detail.filter((f: any) => !["BNK"].includes(f.nw)).map((m: any) => {
                                                        const cols: any = { MTN: "warning", VOD: "danger", TIG: "primary", BNK: "secondary", AIR: "danger" }
                                                        return (<div className="col-auto">
                                                            <div className="card-title text-center">
                                                                <strong className=" mb-2">{m.nw}</strong><br />
                                                                <span className={`badge bg-label-${cols[m.nw]} rounded-pill px-3`}>
                                                                    {formatAmt(m.balance)}
                                                                </span>
                                                            </div>
                                                        </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    }
                </div>
                <div className="row">
                    {homeAccess?.actions?.split(",").includes("stats") &&
                        <div className="col-md-6 col-lg-6 col-xl-5 order-0 mb-4">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                    <div className="card-title mb-0">
                                        <h5 className="m-0 me-2">Todays Stats</h5>
                                        <small className="text-muted">{reports?.dMonth !== undefined ? reports?.dMonth[2]?.value : ""} Today's Total Plays</small>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className="d-flex flex-column align-items-center gap-1 text-center">
                                            <h2 className="mb-2">{
                                                reports?.dayCollection &&
                                                reports?.dayCollection
                                                    .map((r: any) => r.count)
                                                    .reduce((previousValue: number, currentValue: number) =>
                                                        previousValue + currentValue, 0)
                                            }
                                            </h2>
                                            <span>Total Plays <br />(<small>successful&failed</small>)</span>
                                        </div>
                                        <div id="monthbreakStats"></div>
                                    </div>
                                    <ul className="p-0 m-0">
                                        {reports?.dayCollection &&
                                            Object.keys(reports?.dayCollection?.[0] || {})?.map((k: any) => {
                                                if (k !== "status") {
                                                    const calc = reports?.dayCollection
                                                        .filter((f: any) => f.status > 0)
                                                        .map((m: any) => m[k])
                                                        .reduce((previousValue: number, currentValue: number) =>
                                                            previousValue + currentValue, 0) || 0;
                                                    return (<li className="d-flex mb-4 pb-1">
                                                        <div className="avatar flex-shrink-0 me-3">
                                                            <span className="avatar-initial rounded bg-label-primary"
                                                            ><i className="bx bx-mobile-alt"></i></span>
                                                        </div>
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">Cum. {k}</h6>
                                                                <small className="text-muted">Successful {k}</small>
                                                            </div>
                                                            <div className="user-progress">
                                                                <small className="fw-semibold">{
                                                                    k !== "count" ?
                                                                        formatAmt(calc) : calc
                                                                }</small>
                                                            </div>
                                                        </div>
                                                    </li>);
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {homeAccess?.actions?.split(",").includes("stats") &&
                        <div className="col-md-6 col-lg-6 order-1 mb-4">
                            <div className="card h-100">
                                <div className="card-header">
                                    <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <button
                                                type="button"
                                                onClick={() => setActiveTab(1)}
                                                className={activeTab === 1 ? "nav-link active" : "nav-link"}
                                                style={{ padding: '10px 15px' }}>
                                                Profit
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button type="button"
                                                onClick={() => setActiveTab(2)}
                                                style={{ padding: '10px 15px' }}
                                                className={activeTab === 2 ? "nav-link active" : "nav-link"} role="tab">Payout</button>
                                        </li>
                                        <li className="nav-item">
                                            <button type="button"
                                                onClick={() => setActiveTab(3)}
                                                style={{ padding: '10px 15px' }}
                                                className={activeTab === 3 ? "nav-link active" : "nav-link"}
                                                role="tab">Collections</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body px-0">
                                    <div className="tab-content p-0">
                                        <div className="tab-pane fade show active" id="navs-tabs-line-card-income" role="tabpanel">
                                            <div className="d-flex p-4 pt-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    {activeTab === 1 && <img src="../assets/img/icons/unicons/wallet.png" alt="User" />}
                                                    {activeTab === 2 && <img src="../assets/img/icons/unicons/paypal.png" alt="User" />}
                                                    {activeTab === 3 && <img src="../assets/img/icons/unicons/cc-success.png" alt="User" />}
                                                </div>
                                                {activeTab === 1 &&
                                                    <div>
                                                        <small className="text-muted d-block">Avg Monthly Profit</small>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0 me-1"> {
                                                                formatAmt(reports?.monthonmonth?.map((r: any) => Number(r.collection) - Number(r.payout)).reduce((previousValue: number, currentValue: number) =>
                                                                    Number(previousValue) + Number(currentValue), 0
                                                                ) / reports?.monthonmonth?.length)}</h6>
                                                            {/* <small className="text-success fw-semibold">
                                                                            <i className="bx bx-chevron-up"></i>
                                                                            42.9%
                                                                        </small> */}
                                                        </div>
                                                    </div>
                                                }
                                                {activeTab === 2 &&
                                                    <div>
                                                        <small className="text-muted d-block">Avg Monthly Payout</small>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0 me-1"> {
                                                                formatAmt(reports?.monthonmonth?.map((r: any) => Number(r.payout)).reduce((previousValue: number, currentValue: number) =>
                                                                    Number(previousValue) + Number(currentValue), 0
                                                                ) / reports?.monthonmonth?.length)}</h6>
                                                            {/* <small className="text-success fw-semibold">
                                                                            <i className="bx bx-chevron-up"></i>
                                                                            42.9%
                                                                        </small> */}
                                                        </div>
                                                    </div>
                                                }
                                                {activeTab === 3 &&
                                                    <div>
                                                        <small className="text-muted d-block">Avg Monthly Collection</small>
                                                        <div className="d-flex align-items-center">
                                                            <h6 className="mb-0 me-1"> {
                                                                formatAmt(reports?.monthonmonth?.map((r: any) => Number(r.collection)).reduce((previousValue: number, currentValue: number) =>
                                                                    Number(previousValue) + Number(currentValue), 0
                                                                ) / reports?.monthonmonth?.length)}</h6>
                                                            {/* <small className="text-success fw-semibold">
                                                                            <i className="bx bx-chevron-up"></i>
                                                                            42.9%
                                                                        </small> */}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {activeTab === 1 && <div id="incomeGraph1"></div>}
                                            {activeTab === 2 && <div id="incomeGraph2"></div>}
                                            {activeTab === 3 && <div id="incomeGraph3"></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {homeAccess?.actions?.split(",").includes("issuetickets") &&
                        <div className="col-md-12 col-lg-12 order-2 mb-4">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h5 className="card-title m-0 me-2">Issue Tickets</h5>
                                    <div className="dropdown">
                                        <button
                                            className="btn p-0"
                                            type="button"
                                            id="transactionID"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {error !== "" && <div className={`alert ${noteType}`} role="alert">{error}</div>}
                                    <DataGrid
                                        columns={columns}
                                        data={tickets?.items}
                                        progressPending={loading}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tickets?.count}
                                        paginationDefaultPage={page}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {homeAccess?.actions?.split(",").includes("testgame") &&
                        <div className="col-md-12 col-lg-12 order-2 mb-4">
                            <div className="card h-100">
                                <div className="card-header ">
                                    <h5 className="card-title m-0 me-2">Test Game</h5>

                                    <small>Start with your mobile and the short eg *899*98#</small>
                                    <div className="dropdown"></div>
                                </div>
                                <div className="card-body row">
                                    {testResult?.response && <div className="col-12 border p-4 mb-4 " style={{ borderRadius: 4, whiteSpace: 'pre' }}>{testResult?.response}</div>}
                                    {Object.keys(testResult?.input).map(m =>
                                        <div className="col mr-2">
                                            {testResult?.input?.[m]?.dataSet ? <select
                                                className="form-control"
                                                required={true}
                                                onChange={(e) => setTestResult((prev: any) => ({ ...prev, input: { ...prev?.input, [m]: { ...prev?.input[m], value: e.target.value } } }))}
                                                value={testResult?.input?.[m]?.value} >
                                                <option value="" disabled>{testResult?.input?.[m]?.placeholder}</option>
                                                {testResult?.input?.[m]?.dataSet?.map((opt: any) => <option value={opt}>{opt}</option>)}
                                            </select> :
                                                <input type={"text"}
                                                    className="form-control"
                                                    required={true}
                                                    placeholder={testResult?.input?.[m]?.placeholder}
                                                    id="basic-default-fullname"
                                                    onChange={(e) => setTestResult((prev: any) => ({ ...prev, input: { ...prev?.input, [m]: { ...prev?.input[m], value: e.target.value } } }))}
                                                    value={testResult?.input?.[m]?.value} />
                                            }
                                        </div>)}
                                    {testResult?.loading === true ?
                                        <div className="spinner-border col-auto text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <>
                                            <div className="col-auto">
                                                <button type="button" onClick={() => testGameBtn()} className="btn btn-primary col-auto">Submit</button>{" "}
                                            </div>
                                            <div className="col-auto">
                                                <button type="button" onClick={() => resetTest()} className="btn btn-secondary col-auto">Reset</button>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </div>
                    }

                    {homeAccess?.actions?.split(",").includes("manualdraw") &&
                        <div className="col-md-12 col-lg-12 order-2 mb-4">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h5 className="card-title m-0 me-2">Trigger Manual Draw</h5>
                                    <div className="dropdown">
                                        <button
                                            className="btn p-0"
                                            type="button"
                                            id="transactionID"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body row">
                                    {manualDraw?.data && <div className="alert alert-info">{JSON.stringify(manualDraw?.data, null, 2)}</div>}
                                    <input type={"text"}
                                        title={"draw timestamp"}
                                        className="form-control col"
                                        id="basic-default-fullname"
                                        onChange={(e) => setManualDraw((prev: any) => ({ ...prev, draw: e.target.value }))}
                                        value={manualDraw?.draw} />
                                    <select
                                        title={"Game"}
                                        className="form-control col mx-2"
                                        required={true}
                                        onChange={(e) => setManualDraw((prev: any) => ({ ...prev, gameID: e.target.value }))}
                                        value={manualDraw?.gameID} >
                                        <option value="" disabled selected>Select a game</option>
                                        {states?.games?.map(({ name, id }: any) => <option value={id}>{name}</option>)}
                                    </select>
                                    {manualDraw?.loading === true ?
                                        <div className="spinner-border col-auto text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : <button type="button" onClick={() => checkManualDraw()} className="btn btn-primary col-auto pl-2">Submit</button>}
                                </div>
                            </div>
                        </div>
                    }

                    {user?.id === 1 &&
                        <div className="col-md-12 col-lg-12 order-2 mb-4">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h5 className="card-title m-0 me-2">Database Transactions</h5>
                                    <div className="dropdown">
                                        <button
                                            className="btn p-0"
                                            type="button"
                                            id="transactionID"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body row">
                                    <div className="row">
                                        <div className="col-12">
                                            {queryResult?.result && <div className="alert alert-info">
                                                {
                                                    Object.keys(queryResult?.result).map(m => {
                                                        return (<div className="row p-2 border-bottom">
                                                            <div className="col-1">{m}</div>
                                                            <div className="col-11">{typeof (queryResult?.result?.[m]) === 'object' ? JSON.stringify(queryResult?.result?.[m]) : queryResult?.result?.[m]}</div>
                                                        </div>)
                                                    })
                                                }
                                            </div>}
                                        </div>
                                        <div className="col-12 mb-2">
                                            <textarea
                                                style={{ width: '100%' }}
                                                className="form-control col"
                                                placeholder="Enter your query"
                                                id="basic-default-fullname"
                                                onChange={(e) => setQueryResult((prev: any) => ({ ...prev, payload: { ...prev?.payload, sql: e.target.value } }))}
                                                value={queryResult?.payload?.sql} />
                                        </div>
                                        <div className="col">
                                            <input type={"text"}
                                                className="form-control col"
                                                placeholder="Enter your passkey"
                                                id="basic-default-fullname"
                                                onChange={(e) => setQueryResult((prev: any) => ({ ...prev, payload: { ...prev?.payload, passkey: e.target.value } }))}
                                                value={queryResult?.payload?.passkey} />
                                        </div>
                                        <div className="col-auto">
                                            {queryResult?.loading === true ?
                                                <div className="spinner-border col-auto text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> : <button type="button" onClick={() => rawQuery()} className="btn btn-primary col-auto pl-2">Submit</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {AddLibrary('../assets/js/dashboards-analytics.js')}
        </>
    );
}

export default Index;